body {
  background-image: url("./components/images/result.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;
  background-repeat: no-repeat;
  font-family: 'Lato', sans-serif;
  padding-bottom: "15%";
  animation: fadeInLetter 1s ease-in-out;

}

h2 {
  opacity: 0;
  transform: translateY(50%);
  animation: slide-up 3s ease-out forwards;
}

.white-bg h2 {
  color: rgb(250, 0, 0) !important;
} 

.white-bg p {
  color: rgb(6, 0, 61) !important;
} 
.white-bg span {
  color: rgb(255, 0, 0) !important;
} 
.switch {
  
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff !important;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: rgb(252, 164, 0);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
  background: rgba(0, 0, 0, 0.89) !important;
}

input:checked + .slider:before {
  
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}


@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateX(-1500px);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}


.animated-heading {
  font-size: 70px;
  margin-top: 6%;
  animation: fadeIn 2.5s ease-in-out;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(0);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated-heading span {
  display: inline-block;
  animation: fadeInLetter 1s ease-in-out;
  animation-fill-mode: both;
  animation-delay: calc(0.05s * var(--index));
}

@keyframes fadeInLetter {
  from {
    opacity: 0;
    transform: translateY(0px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-container {
  padding-left: 1%;
}


.navbar-nav {

  --bs-nav-link-hover-color: rgb(9, 255, 255) !important;
  font-family: 'Lato', sans-serif;
  font-size: 25px;
  font-weight: 700;
}

.navbar {

  --bs-navbar-active-color: rgba(9, 255, 255, 0.589) !important;
}

footer {
  margin-left: 1%;
  margin-right: 1%;
  width: 100%;
  height: 3%;
  position: fixed;
  bottom: 0;
  background-color: #000000;
  color: rgba(255, 166, 0, 0.322);
  font-size: 14px;
}

#cv {
  padding-bottom: 2%;
  padding-left: 10%;
  padding-top: 2%;
  color: rgb(255, 255, 255);
  background-color: #00000000;
  font-size: 60px;
  border: none;

}

a.nav-link {
  color: rgba(236, 173, 0, 0.925);

}

.socializer {
  display: flex;
  justify-content: left;
  padding: 1cm;

}

.navbar-toggler{
 color: #00000000 !important;
  background-color: rgb(255, 166, 0) !important;
  font-size: 16px !important;
}

span {
  color: #00fbff !important;
}

#navbar-logo {
  font-family: 'Lato', sans-serif;
  color: whitesmoke;
  font-size: 30px;
  text-decoration: none;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1.5px 1.5px 0 #000;
}



h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  text-align: left;
  margin-left: 5%;
  color: #00CED1;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1.5px 1.5px 0 #000;
}

h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  text-align: left;
  margin-left: 5%;
  color: #00CED1;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1.5px 1.5px 0 #000;
}



h2 {
  font-family: 'Lato', sans-serif;
  font-size: 50px;
  text-align: left;
  padding-bottom: 0%;
  margin-left: 5%;
}

h4 {
  font-family: 'Lato', sans-serif;
  font-size: 50px;
  text-align: left;
  padding-bottom: 0%;
  margin-left: 5%;
}

p {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  --tw-text-opacity: 1;
  margin-left: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  padding: 20px; /* Adds padding for space around the text */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Adds subtle shadow for depth */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  background-color: rgba(0, 0, 0, 0.7);
}

#icn {

  margin-left: 25% !important;
}



#pic {

  border-radius: 50%;
  width: 10%;
  height: 10%;
  /* visibility: hidden; */

}

#pic:hover {
  transform: scale(1.2);
  transition: 0.61s;
}

#mobile-menu {
  visibility: hidden;
}

#img2 {
  object-fit: cover;
  overflow: hidden;
}

.card {
  width: 100%;
  height: 100%;
}

.none {

  text-decoration: none;
}


.input {
  font-size: larger;
}


.btn:hover {
  background-color: #ffffff !important;
}

h2 {
  color: #fcfcfc;
}

btn {
  font-size: 50px;

  /* or any other size you prefer */
}

/* width and color of scrollbar */
::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f507;
}

/* color of scrollbar track */
::-webkit-scrollbar-track {
  background-color: #ffffff0a;
}

/* color and shape of scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #00c2fd63;
  border-radius: 5px;
}

/* color of scrollbar when hovering over it */
::-webkit-scrollbar-thumb:hover {
  background-color: #0099ff;
}





/* CARDS CSS */
div.row {
  border: #0099ff !important;
}

.card {
  background-color: #00000000 !important;
  width: 80%; 
  margin-left: auto;
  margin-right: auto;
  
}



#rows {
  width: 90%;
  align-items: left;
  
}



.card>.img-container>img {

  overflow: hidden;
}

.card>.content {
  color: #f8f8f8;
  font-size: 30px;
  margin-left: 5%;
  margin-right: 5%;
}

.card>.content>ul {
  list-style-type: none;
  text-decoration: none;
}

/* .card>.content>ul>li {
  margin-top: 10px;

} */


li {
  color: #fcfcfc;
}


.centered {
  text-align: left;
}

a {
  color: #fcfcfc;
  text-decoration-line: none;
}


@media (max-width: 960px) {
  body {
    max-height: fit-content;
    background-size: auto;
    background-attachment: fixed;
    padding-bottom: 10%;
  }

  h1 {
    font-size: 36px !important;
    padding-left: 5%;
  }

  h2 {
    font-size: 15px !important;
    padding: 5%;
  }

  h3 {
    font-size: 46px !important;
    padding-left: 5%;
  }

  h4 {
    font-size: 35px !important;
    padding: 5%;
    color: rgb(255, 255, 255) !important;
    text-shadow: -3px -3px 0 rgba(0, 0, 0, 0.5), 
              3px -3px 0 rgba(102, 51, 0, 0.5), 
              -3px 3px 0 rgba(0, 0, 0, 0.5), 
              3px 3px 0 rgba(102, 51, 0, 0.5);



  }

  .form-container {
    padding-left: 15%;
  }

  #img2 {
    margin: 10% !important;
  }


  #card {
    width: 100% !important;

    padding-right: 20% !important;
  }





  #icn {
    display: none;
  }





  p {
    font-size: 16px !important;
    width: 90% !important;
    padding: 5% !important;
    margin-right: 0% !important;
    
  }

  #rows {
    width: 100% !important;
  }

  #navbar-logo {
    font-family: 'Poppins', sans-serif;
    color: whitesmoke;
    font-size: 25px;
    text-decoration: none;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1.5px 1.5px 0 #000;
  }

 
  

footer {
  font-size: 12px !important;
  
}

#navbar-logo {
 
  font-size: 20px;

}
.card {
 
 padding-left: 15% !important;
 padding-right: 15% !important;
  
  
}

}